.rdt_Table{
  border: 1px rgba(0,0,0,.12) solid;
}
.TpgDh {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #777777 !important;
}
.rdt_TableCell{
  font-weight: 500;
  font-size: 12px;
  color: #777777 !important;
}
.bLyqNT{
  /* width: unset !important;
  width: unset  !important;
  height: unset !important;
  text-align: center !important;
  margin: auto !important; */
}
.iXRfeU {
  max-height: unset !important;
}
.form-control{
    background-color: #eef3f7 !important;
    border-color: #eef3f7 !important;
    color: #5e6278 !important;
    transition: color .2s ease,background-color .2s ease;
    box-shadow: none!important;
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem;
    /* font-size: 1.15rem; */
    border-radius: 0.475rem !important;
}
.bg-img2{
    background-image: url('../../../../public/assets2/images2/login.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
    background-attachment:fixed;
}

.btn-mine {
    background-color: #0095e8!important;
    font-size: 1.15rem;
    border-radius: 0.475rem !important;
    color: #fff !important;
}
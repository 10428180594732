
.bc-dblue{
    background-color: #212331 !important;
    color: #80828c;
}
.text-tdark{
    color: #80828c;
}

.active_classname
{
    /* background-color: #5584AC !important; */
    background-image: linear-gradient(to bottom right, #430D7D, #6E179F);
    color: #fff;
}

.active_language_classname
{
    background-color: #5584AC !important;;
    color: #fff;
}
.b-img {
    background-image: url('/public/assets2/images2/homeslider2.jpg') !important;
    /* background-position: 100%; */
    background-size: cover;
    color: #fff;
    background : rgba(0,0,0,0.1);
    border: unset !important;
}
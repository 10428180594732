.scroll {
    max-height: 60vh;
    /* overflow-x: scroll; */
    overflow-y: scroll;
}
.scroll::-webkit-scrollbar {
    width: 2.5px;
}
/* Track */
.scroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: blue; 
    border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: blue; 
}
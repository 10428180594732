#LandingPage{
.fet_pr-carousel-box-text.fl-wrap {
    background-color: oldlace;
}

.fet_pr-carousel-box-text h3 {
    font-size: 16px;
    font-weight: 550;
    color: #0424b3;
}

.fet_pr-carousel-box-text a {
    color: black;
    font-size:
        16px;
}

.hidden-works-item-text ul li a {
    border: 1px solid #464646;
    background-color: #9400d324;
    padding: 4px;
    line-height: 4em;
    color: white;

}

.hidden-works-item-text ul li a:hover {
    color: white;
    background-color: #430d7d;

}

/* modal styles */
/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 100;
    /* Sit on top */
    left: 0;
    top: 0;
    float: right;
    padding-left: 5px;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
    /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.close1 {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close1:hover,
.close1:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.close2 {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close2:hover,
.close2:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.close3 {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close3:hover,
.close3:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.section-subtitle {

    font-size: 125px !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;

}
.text-danger {
    color: red;
    font-weight: 500;
}

@media screen and (max-width: 480px) {
    .modal-content {
        width: 90%;
    }
}
}

#scroll {
    overflow: auto;
    height: 800px;
}
/*
#scroll::-webkit-scrollbar {
    width: 2.5px;
}
/* Track 
#scroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

/* Handle 
#scroll::-webkit-scrollbar-thumb {
    background: blue; 
    border-radius: 10px;
}

/* Handle on hover 
#scroll::-webkit-scrollbar-thumb:hover {
    background: blue; 
}*/
.scroll1 {
    max-height: 25vh;
    overflow-x: hidden; 
    overflow-y: auto;
}
/*
.scroll1::-webkit-scrollbar {
    width: 2.5px;
}
/* Track */
/* .scroll1::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
} */

/* Handle */
/* .scroll1::-webkit-scrollbar-thumb {
    background: blue; 
    border-radius: 10px;
} */

/* Handle on hover */
/* .scroll1::-webkit-scrollbar-thumb:hover {
    background: blue; 
} */
.react-player__preview{
    border-radius: 5px;
}

.cart-select-size {
    border: 2px solid gray;
    border-radius: 50px;
    min-width: 50px;
    min-height: 50px;
}
.cart-select-size:hover{
    border-color: pink;
    background-color: #CCD1E4;
}
.cart-select-items{
    border: 1px solid black;
    padding: 2px 5px;
    border-radius: 2.5px;
    margin-right: 2.5px;
}
.cart-select-items:hover{
    background-color: #5584AC;
}
.cart-select-items-color{
    background-color: #5584AC;
}
.cart-text-items{
    color: black;
}
.cart-text-items:hover{
    color: white;
}

/* Font Sizes */
.font-size-10px { font-size: 10px !important; }
.font-size-11px { font-size: 11px !important; }
.font-size-12px { font-size: 12px !important; }
.font-size-13px { font-size: 13px !important; }
.font-size-14px { font-size: 14px !important; }
.font-size-15px { font-size: 15px !important; }
.font-size-16px { font-size: 16px !important; }
.font-size-17px { font-size: 17px !important; }
.font-size-18px { font-size: 18px !important; }

/* Text colors */
.text-color-blue { color: #777dff !important;}
.text-color-grey { color: #696995 !important; }
.text-color-pink { color: #ca068f !important; }
.text-color-green { color: #028c1d !important; }

/* background Colors */
.bg-dark-blue { background: #0d0e15 !important; }
.bg-light-blue { background: #001791 !important; }

.bg-black { background: #000000 !important; }
.bg-black-01 { background:  #161718 !important; }
.bg-black-02 { background: #0b0c0c !important; }


/* - - - Border css - - -  */
/* Border Solid */
.border-solid-1px {  border: 1px solid #eee !important;  }
.border-solid-2px {  border: 2px solid #eee !important;  }
.border-dashed-all-2px {  border: 2px dashed #eee !important;  }

/* Border Dashed */
.border-dashed-1px { border-top: 1px dashed #eee !important; }
.border-dashed-2px { border-top: 2px dashed #eee !important; }
.border-dashed-2px-bt { border-bottom: 2px dashed #eee !important; }
.border-dashed-2px-rt { border-right: 2px dashed #eee !important; }
.border-dashed-2px-lt { border-left: 2px dashed #eee !important; }
.no-wrap {
  white-space: nowrap !important;
}
.border_radius-10 {
  border-radius: 10px !important;
}
/* Badges */
.badge-blue-ot-rounded { 
    background-color: #00189100 !important;
    border: 2px solid #001791 !important;
    border-radius: 7px !important;
 }

 /* Buttons */

 .btn {
    margin-left: 10px;
    margin-right: 10px;
  }
  /* Boostrap Buttons Styling */
  
  .btn-default {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgba(108, 88, 179, 0.75);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgba(108, 89, 179, 0.75);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-default:hover {
    color: #FFF;
    background: rgba(108, 88, 179, 0.75);
    border: 2px solid rgba(108, 89, 179, 0.75);
  }
  
  .btn-primary {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgba(58, 133, 191, 0.75);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgba(58, 133, 191, 0.75);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-primary:hover {
    color: #FFF;
    background: rgba(58, 133, 191, 0.75);
    border: 2px solid rgba(58, 133, 191, 0.75);
  }
  
  .btn-success {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgba(103, 192, 103, 0.75);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgba(103, 192, 103, 0.75);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-success:hover {
    color: #FFF;
    background: rgb(103, 192, 103, 0.75);
    border: 2px solid rgb(103, 192, 103, 0.75);
  }
  
  .btn-info {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgba(91, 192, 222, 0.75);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgba(91, 192, 222, 0.75);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-info:hover {
    color: #FFF;
    background: rgba(91, 192, 222, 0.75);
    border: 2px solid rgba(91, 192, 222, 0.75);
  }
  
  .btn-warning {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgba(240, 173, 78, 0.75);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgba(240, 173, 78, 0.75);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-warning:hover {
    color: #FFF;
    background: rgb(240, 173, 78, 0.75);
    border: 2px solid rgba(240, 173, 78, 0.75);
  }
  
  .btn-danger {
    font-family: Raleway-SemiBold;
    font-size: 13px;
    color: rgba(217, 83, 78, 0.75);
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid rgba(217, 83, 78, 0.75);
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-danger:hover {
    color: #FFF;
    background: rgba(217, 83, 78, 0.75);
    border: 2px solid rgba(217, 83, 78, 0.75);
  }

  .input_back {
    background: linear-gradient(45deg, #ccc, #cbcbcb) !important;
    border: 1px solid #ccc !important;
    color: #000 !important;
  }

  .bx-shadow {
    height: auto !important;
  }
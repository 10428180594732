.dzu-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 60px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    transition: all .15s linear;
    border: 2px solid #d9d9d9;
    border-radius: 4px;
    margin-top: 10px;
  }
  .dzu-inputLabel {
      margin-bottom: 0px;
  }

  .dzu-previewContainer{
      padding: 10px 3%;
  }
  .product-border {
      border:rgb(32, 34, 36) solid 1px;
  }